const routes = [
  {
    path: '/',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
  },
  {
    path: '/barcode/:barcode',
    name: 'barcode',
    component: () => import(/* webpackChunkName: "barcode" */ '../views/Barcode.vue'),
    props: route => ({
      barcode: route.params.barcode,
    })
  },
  {
    path: '/qr/:barcode/:hash',
    name: 'qr',
    component: () => import(/* webpackChunkName: "qr" */ '../views/Qr.vue'),
    props: route => ({
      barcode: route.params.barcode,
      hash: route.params.hash,
    })
  },
  {
    path: '/extension/:referenceNumber/:barcode',
    name: 'extension',
    component: () => import(/* webpackChunkName: "extension" */ '../views/Extension.vue'),
    props: route => ({
      referenceNumber: route.params.referenceNumber,
      barcode: route.params.barcode,
    })
  },
  {
    path: '/input/:referenceNumber',
    name: 'input',
    component: () => import(/* webpackChunkName: "input" */ '../views/Input.vue'),
    props: route => ({
      referenceNumber: route.params.referenceNumber,
      type: 'shipment',
    })
  },
  {
    path: '/batch/:referenceNumber',
    name: 'batch',
    component: () => import(/* webpackChunkName: "batch" */ '../views/Input.vue'),
    props: route => ({
      referenceNumber: route.params.referenceNumber,
      type: 'batch_item',
    })
  },
  {
    path: '/:trackingNumber',
    name: 'show',
    component: () => import(/* webpackChunkName: "show" */ '../views/Show.vue'),
    props: true,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

export default routes